import { CSSProperties } from 'react';
import { BodyContainer, Container, Header, HeaderIcon, HeaderText } from './styles';
import Icon from 'components/icons';
import { ICONS } from 'components/icons';

interface IImportantSectionProps {
  children?: React.ReactNode;
  icon?: keyof typeof ICONS;
  title: string;
  style?: CSSProperties;
  headerColor?: string;
  borderColor?: string;
  color?: string;
  noMobile?: boolean;
}

const ImportantSection = ({
  title,
  icon,
  children,
  style,
  headerColor = 'var(--important-yellow-gradient)',
  borderColor = ' var(--egg-orange)',
  color = 'var(--important-black)',
  noMobile = false,
}: IImportantSectionProps): JSX.Element => {
  return (
    <Container
      noMobile={noMobile}
      style={{
        height: '100%',
        maxHeight: '351px',
        minHeight: '146px',
        border: `1px solid ${borderColor}`,
        ...style,
      }}
    >
      <Header style={{ background: headerColor, color: color }}>
        {icon && (
          <HeaderIcon>
            <Icon icon={icon} size="small" />
          </HeaderIcon>
        )}
        <HeaderText>{title}</HeaderText>
      </Header>
      <BodyContainer>{children}</BodyContainer>
    </Container>
  );
};

export default ImportantSection;
