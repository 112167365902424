import { AxiosResponse } from 'axios';
import axios from 'axios';
import { config } from 'config/config.local';
import { IGenIAResponse, ISearchGenIAParams } from 'contexts/gen_ia/types';

// Create an Axios instance since there is no token handling for this endpoint yet
const apiClient = axios.create({
  baseURL: config.routes.genIA.search,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const searchText = async ({
  query,
  pageSize,
  fileTypes,
}: ISearchGenIAParams): Promise<AxiosResponse<IGenIAResponse>> => {
  const paramsParser = {
    query,
    target_videos: pageSize,
    search_space: 20,
    use_rag: true,
    include_explanation: true,
    include_skills: true,
    evaluate_relevance: true,
    index_name: fileTypes[0] || 'videos',
  };
  return await apiClient.post(config.routes.genIA.search, paramsParser);
};
