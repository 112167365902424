import styled from 'styled-components';
import { screenSm } from 'constants/styles/breakpoints';

export const Container = styled.div<{ noMobile?: boolean }>`
  position: relative;
  display: ${({ noMobile }) => (noMobile ? 'none' : 'flex')};
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 196px;
  padding: 0 20px 20px 30px;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 5px 20px 0px rgba(255, 123, 0, 0.09);
  ${screenSm} {
    display: ${(noMobile) => noMobile && 'flex'};
  } ;
`;

export const Header = styled.div`
  position: relative;
  left: -30px;
  display: flex;
  gap: 3px;
  justify-content: center;
  align-items: center;
  width: calc(100% + 50px);
  height: 30px;
  border-radius: 8px 8px 0 0;
`;

export const HeaderText = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
`;
export const HeaderIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 17px;
  z-index: 1;
  svg {
    width: 16px;
    height: 17px;
  }
`;
export const BodyContainer = styled.div`
  min-height: 146px;
  max-height: 311px;
`;
