import { createContext } from 'react';
import { FCProps } from 'types/FCProps';

import { ICertificationsDataCtx } from './types';
import { certificationsInitialValues } from './utils';

import useCertificationsContent from 'hooks/useCertificationsContent';

export const CertificationContext = createContext<ICertificationsDataCtx>(
  certificationsInitialValues
);

const CertificationsProvider = ({ children }: FCProps): JSX.Element => {
  const {
    counters,
    fetching,
    fetchingCounters,
    fetchingUsers,
    fetchingTable,
    fetchingEmptyState,
    users,
    usersTable,
    usersCount,
    emptyState,
    voucherExpirations,
    filters,
    changeFilters,
  } = useCertificationsContent();

  const actions = {
    onChangeFilters: changeFilters,
  };

  return (
    <CertificationContext.Provider
      value={{
        counters,
        fetching,
        fetchingUsers,
        fetchingCounters,
        fetchingTable,
        fetchingEmptyState,
        users,
        usersCount,
        usersTable,
        emptyState,
        voucherExpirations,
        filters,
        actions,
      }}
    >
      {children}
    </CertificationContext.Provider>
  );
};

export default CertificationsProvider;
