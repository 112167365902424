import React, { useContext, useMemo } from 'react';
import { CertificationContext } from 'contexts/certifications';
import {
  CertificationsContainer,
  ColumnNameIcon,
  Container,
  InfoTooltipCellContainer,
  InfoTooltipCellData,
  PaginationContainer,
  Title,
} from './styles';
import { Table, User } from 'assets/styles/styles';
import Button from 'components/button/Button';
import CertificationsFilters from '../CertificationsFillters/CertificationsFilters';
import { capitalize, formatDate, formatScore } from 'utils/helpers';
import { Spinner } from 'components/loadings';
import { Pagination } from 'components/Table';
import Tooltip from 'components/tooltip';
import { certificationStatus, certifications } from 'constants/certifications';
import ButtonIcon from 'components/buttonIcon';
import Icon from 'components/icons';
import displayTeams from 'components/displayTeams/DisplayTeams';

interface CertificationTableProps {
  noMobile?: boolean;
}

const CertificationTable: React.FC<CertificationTableProps> = ({ noMobile = false }) => {
  const { actions, filters, usersTable, fetchingTable, fetching, usersCount } =
    useContext(CertificationContext);

  const isFetching = useMemo(() => fetching || fetchingTable, [fetching, fetchingTable]);

  const tableColumns = '77px minmax(172px, 1.3fr) minmax(160px, 1fr) 101px 106px 120px 58px 36px';

  const handleCertificationLink = (url: string) => () => {
    window.open(url, '_blank');
  };

  const InfoIconTooltip = ({ title, color = '' }) => {
    const fillColor = color || 'var(--lilac)';
    return (
      <Tooltip
        title={title}
        placement="right"
        overlayInnerStyle={{ textAlign: 'center', maxWidth: '255px' }}
      >
        <Icon data-testid="info-inactive-icon" icon="Info" fill={fillColor} size="small" />
      </Tooltip>
    );
  };

  const displayCertification = (certification: string): string => {
    const cert = certification.toUpperCase();
    return certifications[cert]?.name;
  };

  const handlePaginationChange = (key, value) => {
    const reset_page = key === 'page_size' ? { page: 1 } : {};
    actions.onChangeFilters({ ...filters, [key]: Number(value), ...reset_page });
  };
  const SortButton = () => {
    const value = filters.ordering === 'date' ? '-date' : 'date';
    const sortOption = { active: false };
    const sortAscIcon = value === '-date';
    return (
      <Button
        $primary={sortOption.active}
        $terciary={!sortOption.active}
        size="small"
        icon={sortAscIcon ? 'SortDown' : 'Sort'}
        onClick={() => actions.onChangeFilters({ ...filters, ['ordering']: value })}
      />
    );
  };

  const expiresSoon = (expiration_date: string): boolean => {
    const expirationDate = new Date(expiration_date);
    const today = new Date();
    const diffTime = expirationDate.getTime() - today.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= 15 && diffDays >= 0;
  };

  const tableHasData = useMemo(() => usersTable?.length > 0, [usersTable]);

  return (
    <Container noMobile={noMobile}>
      <Title>Certification History</Title>
      <CertificationsContainer>
        <CertificationsFilters />
        <Table.Container style={{ width: '100%', height: '465px', overflowY: 'auto' }}>
          {isFetching && (
            <Table.Loading>
              <Spinner active={isFetching} />
            </Table.Loading>
          )}
          {!isFetching && tableHasData && (
            <>
              <Table.Container>
                <Table.Thead>
                  <Table.Tr
                    borderBottom={true}
                    columns={tableColumns}
                    hasScroll={usersTable?.length > 6}
                  >
                    <Table.Th withSort={true}>
                      Date
                      <SortButton />
                    </Table.Th>
                    <Table.Th>User</Table.Th>
                    <Table.Th>Team(s)</Table.Th>
                    <Table.Th>
                      <ColumnNameIcon style={{ marginRight: '2px' }}>Certification</ColumnNameIcon>
                      <InfoIconTooltip title="Certification short name" />
                    </Table.Th>
                    <Table.Th>
                      <ColumnNameIcon style={{ marginRight: '2px' }}>Status</ColumnNameIcon>
                      <InfoIconTooltip title="Certification current status, whether assigned, failed, passed, timed out, or expired" />
                    </Table.Th>
                    <Table.Th>
                      <ColumnNameIcon style={{ marginRight: '2px' }}>
                        Expiration date
                      </ColumnNameIcon>
                      <InfoIconTooltip title="Voucher expiration date" />
                    </Table.Th>
                    <Table.Th>
                      <ColumnNameIcon style={{ marginRight: '2px' }}>Result</ColumnNameIcon>
                      <InfoIconTooltip title="Result of this certification attempt, shown as a percentage" />
                    </Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody height="54px">
                  {!isFetching &&
                    usersTable?.length > 0 &&
                    usersTable?.map((report, index) => {
                      const {
                        id,
                        date,
                        user_name,
                        user_email,
                        teams,
                        certification,
                        status,
                        expiration_date,
                        score,
                        accredible_url,
                      } = report;

                      return (
                        <Table.Tr
                          borderBottom={true}
                          key={`${index}-${id}`}
                          columns={tableColumns}
                          hasScroll={usersTable?.length > 6}
                        >
                          <Table.Td>{formatDate(date)}</Table.Td>
                          <Table.Td>
                            <User.Container>
                              <User.Name>{user_name}</User.Name>
                              <User.Email>{user_email}</User.Email>
                            </User.Container>
                          </Table.Td>
                          <Table.Td>{displayTeams({ teams })}</Table.Td>
                          <Table.Td>
                            <Tooltip title={displayCertification(certification)}>
                              {certification}
                            </Tooltip>
                          </Table.Td>
                          <Table.Td>
                            <InfoTooltipCellContainer>
                              <InfoTooltipCellData>{capitalize(status)}</InfoTooltipCellData>
                              <InfoIconTooltip
                                title={certificationStatus[status.toUpperCase()]?.tooltip}
                              />
                            </InfoTooltipCellContainer>
                          </Table.Td>
                          <Table.Td>
                            <InfoTooltipCellContainer>
                              <InfoTooltipCellData>
                                {formatDate(expiration_date)}
                              </InfoTooltipCellData>
                              {expiresSoon(expiration_date) && (
                                <InfoIconTooltip
                                  title="This voucher expires soon"
                                  color="var(--performance-light)"
                                />
                              )}
                            </InfoTooltipCellContainer>
                          </Table.Td>
                          <Table.Td>
                            <span
                              className={`${
                                status === 'Passed' ? 'pill pill--completed w-auto' : ''
                              }`}
                            >
                              {formatScore(score)}
                            </span>
                          </Table.Td>
                          <Table.Td>
                            {accredible_url && (
                              <Tooltip placement="bottomRight" title={'See certification'}>
                                <ButtonIcon
                                  $secondary
                                  size={'small'}
                                  icon={'ExternalLink'}
                                  onClick={handleCertificationLink(accredible_url)}
                                />
                              </Tooltip>
                            )}
                          </Table.Td>
                        </Table.Tr>
                      );
                    })}
                </Table.Tbody>
              </Table.Container>
              <PaginationContainer>
                <Pagination
                  count={usersCount}
                  page_size={filters.page_size}
                  page={filters.page}
                  rowsPerPageList={['10', '20', '30', '50']}
                  onPagesClick={(value, name) => handlePaginationChange(name, value)}
                />
              </PaginationContainer>
            </>
          )}
          {!isFetching && !tableHasData && (
            <Table.EmptyState>
              <Table.EmptyStateTitle>Sorry, we couldn’t find any matches</Table.EmptyStateTitle>
              <Table.EmptyStateText>Please try searching by another term</Table.EmptyStateText>
            </Table.EmptyState>
          )}
        </Table.Container>
      </CertificationsContainer>
    </Container>
  );
};

export default CertificationTable;
