import { handleErrors } from '../helpers';
import { ICertificationStats, IFilterItem, IStatsParams, IVouchersParams } from './types';
import {
  fetchCertificationStats,
  fetchEmptyState,
  fetchUsersCertificationsLogs,
  fetchVoucherExpirations,
} from 'services/certifications';

export const getEmptyStateAction = async (
  business_account_id: string
): Promise<boolean | Error> => {
  try {
    const response = await fetchEmptyState(business_account_id);
    return response.data?.empty_state;
  } catch (error) {
    return handleErrors(error);
  }
};

export const getUsersCertificationsLogsAction = async (
  filters: IFilterItem,
  business_account_id?: string,
  accountTeamIds?: Array<string>
): Promise<{ results: any; count: number } | Error> => {
  try {
    const { certification_short_names, statuses, user_ids, date_range, page, page_size, ordering } =
      filters;

    const payload = {
      business_account_id,
      certification_short_names,
      statuses,
      user_ids,
      team_ids: accountTeamIds?.length ? accountTeamIds : filters.teams?.map((team) => team.id),
      date_range,
      page,
      page_size,
      ordering,
    };

    const response = await fetchUsersCertificationsLogs(payload);
    return {
      results: response.data.results,
      count: response.data.count,
    };
  } catch (error) {
    return handleErrors(error);
  }
};

export const getCertificationsCountersAction = async (
  payload: IStatsParams
): Promise<{ results: ICertificationStats } | Error> => {
  try {
    const response = await fetchCertificationStats(payload);
    return {
      results: response.data,
    };
  } catch (error) {
    return handleErrors(error);
  }
};

export const getVoucherExpirationsAction = async (
  payload: IVouchersParams
): Promise<{ results: any } | Error> => {
  try {
    const response = await fetchVoucherExpirations(payload);
    return {
      results: response.data.results,
    };
  } catch (error) {
    return handleErrors(error);
  }
};
