import { useContext, useState } from 'react';
import styled from 'styled-components';
import Button from 'components/button';
import { screenSm } from 'constants/styles/breakpoints';
import { IGenIAResponse } from 'contexts/gen_ia/types';
import { isDataManager } from 'contexts/session/utilities';
import { SessionContext } from 'contexts/session';

import { searchText } from 'services/genIA';
import { Redirect } from 'react-router-dom';

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 0px;
  padding: 20px;
  width: 100%;
  border-radius: 12px;

  ${screenSm} {
    background-color: #fff;
  }
`;

const InputSection = styled.div`
  display: flex;
  gap: 10px;
`;

const ResponseSection = styled.div``;

export const Input = styled.input<{ error?: boolean }>`
  height: 44px;
  font-size: 12px;
  padding: 15px 10px;
  border: none;
  border-radius: 10px;
  width: 100%;
  background: ${({ theme }) => theme.default.colors.palette.Business_LightThemeGreyLighter};
  &::placeholder {
    color: ${({ theme }) => theme.default.colors.fonts.darkGray};
  }
`;

const GenIA = (): JSX.Element => {
  const [skillDescription, setSkillDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [queryResponse, setQueryResponse] = useState<IGenIAResponse | undefined>(undefined);
  const { user } = useContext(SessionContext);

  if (!isDataManager(user?.uaa_data?.roles?.data?.map(({ id }) => id))) {
    return <Redirect to="/" />;
  }

  const handleSubmit = async () => {
    setLoading(true);
    const response = await searchText({
      query: skillDescription,
      pageSize: 5,
      fileTypes: ['videos'], // 'labs' is not implemented yet
    });
    setQueryResponse(response.data);
    setLoading(false);
  };
  return (
    <Section>
      <h1>GEN AI</h1>
      <InputSection>
        <Input
          placeholder={'Search'}
          onChange={(v) => {
            setSkillDescription(v.target.value);
          }}
        />
        <Button $primary onClick={handleSubmit}>
          Generate
        </Button>
      </InputSection>
      {loading && <ResponseSection>Loading...</ResponseSection>}
      {!loading && queryResponse && (
        <ResponseSection>
          Your file can be accessed from:
          <a href={queryResponse.file_url} target="_blank" rel="noreferrer">
            {queryResponse.file_url}
          </a>
        </ResponseSection>
      )}
    </Section>
  );
};

export default GenIA;
