import styled from 'styled-components';

export const VoucherTable = styled.div`
  min-height: 146px;
  max-height: 311px;
  overflow-y: auto;
  padding-right: 10px;
`;

export const DaysToExpire = styled.div<{ today?: boolean }>`
  border-radius: 20px;
  background-color: ${({ today }) =>
    today ? 'var(--carnation-sonar)' : 'var(--performance-light);'};
  width: fit-content;
  padding: 2px 8px;
`;

export const ExpirationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
