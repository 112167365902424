import { format, sub } from 'date-fns';
import { THIRTY_DAY_RANGE } from 'constants/filters';

import { ICertificationsDataCtx } from './types';

// DEFAULT FILTERS DATES FOR CERTIFICATIONS - 30 DAYS RANGE - DAY_PERIOD.VALUE
export const defaultCertificationsRange = THIRTY_DAY_RANGE;
const dateTo = new Date();
const dateFrom = sub(dateTo, {
  [defaultCertificationsRange.PERIOD]: defaultCertificationsRange.VALUE,
});

export const certificationsInitialValues: ICertificationsDataCtx = {
  users: null,
  fetching: false,
  fetchingUsers: false,
  fetchingCounters: false,
  fetchingTable: false,
  fetchingEmptyState: false,
  emptyState: false,
  counters: {
    total_vouchers_assigned: 0,
    total_certification_attempts_taken: 0,
    percentage_of_users_with_vouchers: 0,
    total_passed_certification_attempts: 0,
  },
  voucherExpirations: [],
  filters: {
    date_range: [format(dateFrom, 'yyyy-LL-dd'), format(dateTo, 'yyyy-LL-dd')],
    teams: [],
    user_ids: [],
    certification_short_names: [],
    statuses: [],
    page: 1,
    page_size: 10,
    ordering: '-date',
  },
};
