import React from 'react';
import { Container, TeamsContainer } from './styles';
import TooltipV2 from 'components/TooltipV2';

interface DisplayTeamsProps {
  teams: string[];
  direction?: 'column' | 'row';
}

const DisplayTeams: React.FC<DisplayTeamsProps> = ({ teams, direction }) => {
  if (teams.length <= 1) return <TeamsContainer>{teams}</TeamsContainer>;

  const firstTeam = teams.slice(0, 1);
  const remainingTeamsCount = teams.length - 1;
  const remainingTeams = teams.slice(1);

  return (
    <Container direction={direction}>
      <TeamsContainer>{firstTeam}</TeamsContainer>
      <TooltipV2
        placement="top"
        content={
          <div>
            {remainingTeams.map((team, index) => (
              <div key={index} style={{ marginBottom: '5px' }}>
                {team}
              </div>
            ))}
          </div>
        }
      >
        <span className="pill pill--plus">{`+${remainingTeamsCount}`}</span>
      </TooltipV2>
    </Container>
  );
};

export default DisplayTeams;
