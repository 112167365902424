import InputSelectMultiple from 'components/inputs/inputSelectMultiple';
import { ReportsContext } from 'contexts/reporting';
import { useContext, useEffect, useState } from 'react';
import './index.scss';
import { noUserOption } from 'constants/reporting';
import Icon from 'components/icons';

interface IUserProps {
  id: string;
  name: string;
}
interface IUserSelectProps {
  onUserChange: (props: IUserProps[]) => void;
  context?: React.Context<any>;
  flat?: boolean;
  maxTagCount?: number;
}

const UserSelect = ({
  onUserChange,
  context = ReportsContext,
  flat,
  maxTagCount,
}: IUserSelectProps): JSX.Element => {
  const { users, getUsers, fetchingUsers } = useContext(context);
  const [usersOptions, setUsersOptions] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const allUsersOption = {
    name: 'All users',
    email: noUserOption,
    id: noUserOption,
  };

  useEffect(() => {
    const newUsers = users.filter((u) => !selectedIds.includes(u.id));
    const fullOptionList = selectedUsers.concat(newUsers);
    const newOptions =
      fullOptionList && fullOptionList.length > 0
        ? [allUsersOption].concat(fullOptionList).map((u) => {
            return {
              label: u.name,
              email: u.email,
              value: u.id,
              key: u.email,
            };
          })
        : [];
    setUsersOptions(newOptions);
  }, [users]);

  const onChange = (usersIds: string[]) => {
    const newUsers = [];
    const allUsersOptionSelected = usersIds.find((u) => u === allUsersOption.id);
    if (!allUsersOptionSelected) {
      usersIds.forEach((userId) => {
        const newUser =
          selectedUsers?.find(({ id }) => id === userId) ?? users?.find(({ id }) => id === userId);
        newUsers.push(newUser);
      });
      setSelectedIds(usersIds);
    } else {
      setSelectedIds([]);
    }
    setSelectedUsers(newUsers);
    onUserChange(newUsers);
  };

  const OptionsRender = (option: { label; email; value; key }): JSX.Element => {
    if (option.value === noUserOption) {
      return (
        <div className={'user-select__user-data'}>
          <span className={'user-select__user-label'}>{'All the account users'}</span>
        </div>
      );
    }
    return (
      <div className={'user-select__user-data'}>
        <span className={'user-select__user-data-label'}>{option.label}</span>
        <span className={'user-select__user-data-email'}>{option.email}</span>
      </div>
    );
  };

  const searchUsers = async (value) => {
    return await getUsers(value);
  };

  const customFiltering = (value, option): boolean => {
    return (
      option.label.toLowerCase().includes(value.toLowerCase()) ||
      option.key.toLowerCase().includes(value.toLowerCase())
    );
  };

  return (
    <div
      className={`user-select__filter-container ${
        flat ? 'user-select__filter-container--flat' : ''
      } `}
    >
      <div className={'user-select__filter-content'}>
        <InputSelectMultiple
          showArrow={true}
          suffixIcon={<Icon icon={'ChevronDown'} size={'smaller'} />}
          values={selectedIds}
          placeholder="All users"
          optionFilterProp={'label'}
          options={usersOptions}
          maxDropdownHeight={'150px'}
          onChange={onChange}
          defaultActiveFirstOption={true}
          optionRender={OptionsRender}
          customClassName="user-select"
          maxTagCount={maxTagCount || 5}
          fetchOptionsFunc={searchUsers}
          fetching={fetchingUsers}
          customFilterOptions={customFiltering}
          isAllSelectedOption={(option) => {
            return option.label === 'All users';
          }}
        />
      </div>
    </div>
  );
};

export default UserSelect;
