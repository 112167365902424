export const certifications = {
  CFAZURE: { short_name: 'CFAzure', name: 'Certified Financial Azure' },
  EDA: { short_name: 'EDA', name: 'Enterprise Defense Associate' },
  CAC: { short_name: 'CAC', name: 'Certified Analytics Consultant' },
  EJPT: { short_name: 'eJPT', name: 'eLearn Security Junior Penetration Tester' },
  ICCA: { short_name: 'ICCA', name: 'INE Certified Cloud Associate' },
  CFAWS: { short_name: 'CFAWS', name: 'Certified Financial AWS Specialist' },
  ECPPT: { short_name: 'eCPPT', name: 'INE Certified Professional Penetration Tester' },
  CDFP: { short_name: 'CDFP', name: 'Certified Digital Forensics Professional' },
  EWPT: { short_name: 'eWPT', name: 'Web Application Penetration Tester' },
  MCIT: { short_name: 'MCIT', name: 'Microsoft Certified IT Professional' },
  EWPTX: { short_name: 'eWPTX', name: 'Web Application Penetration Tester eXtreme' },
  CFCERT: { short_name: 'CFCERT', name: 'Cloud Fundamentals Certification' },
  ECDFP: { short_name: 'eCDFP', name: 'Certified Digital Forensics Professional' },
  ECTHP: { short_name: 'eCTHP', name: 'Certified Threat Hunting Professional' },
  ECRE: { short_name: 'eCRE', name: 'Certified Reverse Engineer' },
  ENDP: { short_name: 'eNDP', name: 'Network Defense Professional' },
  ECIR: { short_name: 'eCIR', name: 'Certified Incident Responder' },
  EWDP: { short_name: 'eWDP', name: 'Web Defense Professional' },
  ECPTX: { short_name: 'eCPTX', name: 'Certified Penetration Tester eXtreme' },
  EEDA: { short_name: 'eEDA', name: 'Enterprise Defense Analyst' },
  ECMAP: { short_name: 'eCMAP', name: 'Certified Mobile Application Penetration Tester' },
  ECXD: { short_name: 'eCXD', name: 'Certified Exploit Developer' },
  EMAPT: { short_name: 'eMAPT', name: 'Mobile Application Penetration Tester' },
  ICFZA: { short_name: 'ICFZA', name: 'INE Cloud Fundamentals for Azure' },
  ICFWS: { short_name: 'ICFWS', name: 'INE Cloud Fundamentals for AWS' },
};

export const certificationStatus = {
  ASSIGNED: {
    label: 'Assigned',
    value: 'Assigned',
    key: 'Assigned',
    tooltip: 'This voucher has been assigned but has not been started yet',
  },
  PASSED: {
    label: 'Passed',
    value: 'Passed',
    key: 'Passed',
    tooltip: 'This certification has been successfully completed and approved',
  },
  FAILED: {
    label: 'Failed',
    value: 'Failed',
    key: 'Failed',
    tooltip: 'This attempt at the certification was unsuccessful',
  },
  TIMEOUT: {
    label: 'Time out',
    value: 'Time out',
    key: 'Time out',
    tooltip: 'The user did not submit the certification attempt within the required time frame',
  },
  EXPIRED: {
    label: 'Expired',
    value: 'Expired',
    key: 'Expired',
    tooltip: 'This certification voucher has expired and can no longer be used',
  },
};
