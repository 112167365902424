import CertificationsCounters from './components/CertificationsCounters/CertificationsCounters';
import TeamsFilter from 'components/TeamsFilter/TeamsFilter';
import CertificationTable from './components/CertificationsTable/CertificationsTable';
import CertificationVouchers from './components/CertificationsVouchers/CertificationsVouchers';
import CertificationsProvider, { CertificationContext } from 'contexts/certifications';
import NotificationBanner from 'components/NotificationBanner/NotificationBanner';
import { Container, Counters, Header, Title } from './components/styles';
import { useContext } from 'react';
import { SessionContext } from 'contexts/session';

const Certifications = (): JSX.Element => {
  return (
    <CertificationsProvider>
      <Container>
        <CertificationsContent />
      </Container>
    </CertificationsProvider>
  );
};

const CertificationsContent = (): JSX.Element => {
  const { actions, emptyState, fetchingEmptyState, filters } = useContext(CertificationContext);
  const { isImpersonated } = useContext(SessionContext);

  return fetchingEmptyState ? (
    <>Spinner</>
  ) : emptyState ? (
    <>Empower your team with certifications!</>
  ) : (
    <>
      <Header>
        <Title>Certifications</Title>
        <TeamsFilter actions={actions} filters={filters} noMobile={true} />
      </Header>
      <NotificationBanner
        styles={{ marginTop: '10px', padding: '15px' }}
        impersonated={isImpersonated}
        onlyMobile={true}
        noCloseButton={true}
        infoIcon={true}
        text={`To access the full certifications view, including the certifications history, please switch to a larger screen.`}
      />
      <Counters>
        <CertificationsCounters />
      </Counters>
      <CertificationVouchers noMobile={true} />
      <CertificationTable noMobile={true} />
    </>
  );
};

export default Certifications;
