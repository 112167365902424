import styled from 'styled-components';
import { screenSm } from 'constants/styles/breakpoints';

export const StickyFooterContainer = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1em;
  position: sticky;
  top: 100vh;
  height: 76px;
  align-items: center;
  grid-gap: 15px;
  .settings-bottom-button {
    width: 50% !important;
  }
`;

export const Litle = styled.div<{ noMargin?: boolean; noPadding?: boolean; width?: string }>`
  ${({ noMargin }) => (noMargin ? 'margin-bottom: 0px;' : 'margin-bottom: 3px')};
  ${({ noPadding }) => (noPadding ? 'padding: 0px;' : 'padding: 3px;')};
  ${({ width }) => (width ? `width: ${width}%;` : 'width: 100%;')};
`;

export const Content = styled.div<{ noMargin?: boolean; noPadding?: boolean; flat?: boolean }>`
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.1);
  ${({ flat }) =>
    flat
      ? `
    background: none;
    box-shadow: none;
    `
      : `
    background-color: #fff;
    box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.1);
    `};
  ${({ noMargin }) => (noMargin ? 'margin-bottom: 0px;' : 'margin-bottom: 3px')};
  ${({ noPadding }) => (noPadding ? 'padding: 0px;' : 'padding: 3px')};
`;

export const TitlesContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const Card = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #e9e9ed;
  border-radius: 10px;
  padding: 10px;
  flex-grow: 1;
  height: 90px;
  :nth-child(1) {
    grid-column: span 2;
  }
  :nth-child(2) {
    order: 3;
  }
  :not(:first-child) {
    height: 105px;
  }
  ${screenSm} {
    :not(:first-child) {
      height: 90px;
    }
    :nth-child(2) {
      order: initial;
    }
  }
`;

export const CardQuantity = styled.span`
  font-weight: 700;
  font-size: 42px;
  color: #1a2535;
  text-align: center;
  line-height: normal;

  ${screenSm} {
    line-height: 38px;
  }
`;
export const Label = styled.label<{ color: string; hideDot?: boolean; index?: number }>`
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  color: #57575c;
  display: flex;
  text-align: center;
  height: ${({ index }) => (index && index === 1 ? 'auto' : '32px')};

  ${screenSm} {
    height: auto;
    align-items: center;
  }
  &:before {
    content: '';
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: ${({ color }) => color};
    display: ${({ hideDot }) => (hideDot ? 'none' : 'inline-block')};
    margin-right: 3px;
  }
`;

export const SpinnerContainer = styled.div`
  position: relative;
  opacity: 50%;
`;
