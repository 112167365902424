import { Tooltip } from 'antd';
import { Card, CardQuantity, Container, Label, SpinnerContainer } from './styles';
import Icon from 'components/icons';
import { Spinner } from 'components/loadings';

interface Props {
  quantity: number | string;
  label: string;
  color?: string;
  tooltipLabel?: string;
  displayDot?: boolean;
  fetching?: boolean;
  style?: React.CSSProperties;
  order?: number;
}

const CounterCard = ({
  quantity,
  label,
  color,
  tooltipLabel,
  displayDot,
  fetching,
  style,
  order,
}: Props): JSX.Element => {
  const iconContainerStyles = {
    position: 'absolute',
    top: '10.5px',
    right: '10.5px',
  } as React.CSSProperties;

  return (
    <Card style={{ ...style, position: 'relative' }}>
      {fetching ? (
        <SpinnerContainer>
          <Spinner active={true} size="25" />
        </SpinnerContainer>
      ) : (
        <>
          {tooltipLabel && (
            <Tooltip
              title={tooltipLabel}
              placement="right"
              overlayInnerStyle={{ textAlign: 'center' }}
            >
              <div className={'no-mobile'} style={iconContainerStyles}>
                <Icon
                  data-testid="info-inactive-icon"
                  icon="Info"
                  fill={'var(--lilac)'}
                  size="smaller"
                />
              </div>
            </Tooltip>
          )}
          <Container>
            <CardQuantity>{quantity || 0}</CardQuantity>
            <Label
              hideDot={!displayDot}
              index={order}
              color={displayDot ? color : null}
              dangerouslySetInnerHTML={{ __html: label }}
            ></Label>
          </Container>
        </>
      )}
    </Card>
  );
};

export default CounterCard;
