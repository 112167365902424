import { AxiosResponse } from 'axios';
import { BUSINESS_PYTHON } from './axios';
import { config } from 'config/config.local';
import { ICategory } from 'contexts/categories/types';
import { IFilterItem, IStatsParams, IVouchersParams } from 'contexts/certifications/types';
interface IGetCertifications {
  results: ICategory[];
  count: number;
}

interface ICertificationsFetchParams extends IFilterItem {
  business_account_id: string;
}

export const fetchUsersCertificationsLogs = (
  params: ICertificationsFetchParams
): Promise<AxiosResponse<IGetCertifications>> => {
  return BUSINESS_PYTHON.post(`${config.routes.certifications.logs}`, params);
};

export const fetchCertificationStats = (payload: IStatsParams): Promise<AxiosResponse<any>> =>
  BUSINESS_PYTHON.post(`${config.routes.certifications.stats}`, payload);

export const fetchVoucherExpirations = (payload: IVouchersParams): Promise<AxiosResponse<any>> =>
  BUSINESS_PYTHON.post(`${config.routes.certifications.voucherExpiring}`, payload);

export const fetchEmptyState = (business_account_id: string): Promise<AxiosResponse<any>> =>
  BUSINESS_PYTHON.post(`${config.routes.certifications.emptyState}`, { business_account_id });
