import { ROLES } from 'constants/roles';

export const validateRole = ({ roles = [] }: { roles: string[]; email?: string }): boolean => {
  const accountManagementRole = [ROLES.ADMIN, ROLES.SALES, ROLES.SUPPORT, ROLES.DATA_MANAGER].some(
    (item) => roles.includes(item)
  );
  return accountManagementRole;
};

export const validateAccountOwner = (roles: string[] = []): boolean =>
  roles.includes(ROLES.ACCOUNT_OWNER);
export const validateTeamManager = (roles: string[] = []): boolean =>
  roles.includes(ROLES.TEAM_MANAGER);

export const isTeamManagerOrAccountOwner = (roles: string[] = []): boolean =>
  validateTeamManager(roles) || validateAccountOwner(roles);

export const isStaffMember = (roles: string[] = []): boolean =>
  roles.some((item) => [ROLES.STAFF, ROLES.ADMIN, ROLES.SALES, ROLES.SUPPORT].includes(item));

export const isDataManager = (roles: string[] = []): boolean => roles.includes(ROLES.DATA_MANAGER);

export const findRoles = (rolesToFind: string[], userRoleIds: string[] = []): boolean =>
  rolesToFind.some((userRoleId) => userRoleIds.includes(userRoleId));
