import { ReactComponent as UserIcon } from 'assets/icons/menu-user.svg';
import { ReactComponent as UsersIcon } from 'assets/icons/menu-users.svg';
import { ReactComponent as AccountsIcon } from 'assets/icons/menu-accounts.svg';
import { ReactComponent as PlaylistsIcon } from 'assets/icons/menu-playlists.svg';
import { ReactComponent as PieIcon } from 'assets/icons/menu-pie.svg';
import { ReactComponent as BarsIcon } from 'assets/icons/menu-bars.svg';
import { ReactComponent as ReportingIcon } from 'assets/icons/reporting-icon.svg';
import { ReactComponent as RaxIcon } from 'assets/icons/rax-icon.svg';
import { ReactComponent as GenAIIcon } from 'assets/icons/gen-ai-icon.svg';
import { ReactComponent as Certifications } from 'assets/icons/certifications-icon.svg';

export const MENU_ITEMS = {
  DASHBOARD: {
    text: 'Dashboard',
    to: '/',
    Icon: PieIcon,
  },
  USERS: {
    text: 'Users',
    to: '/users',
    Icon: UserIcon,
  },
  TEAMS: {
    text: 'Teams',
    to: '/teams',
    Icon: UsersIcon,
  },
  ANALYTICS: {
    text: 'Analytics',
    to: '/analytics',
    Icon: BarsIcon,
  },
  REPORTING: {
    text: 'Reporting',
    to: '/reporting',
    Icon: ReportingIcon,
  },
  ACCOUNTS: {
    text: 'Accounts',
    to: '/accounts',
    Icon: AccountsIcon,
  },
  PLAYLISTS: {
    text: 'Playlists',
    to: '/playlists',
    Icon: PlaylistsIcon,
  },
  RAX: {
    text: 'RAX',
    to: '/rax',
    Icon: RaxIcon,
  },
  GENAI: {
    text: 'AI',
    to: '/genAI',
    Icon: GenAIIcon,
  },
  CERTIFICATIONS: {
    text: 'Certifications',
    to: '/certifications',
    Icon: Certifications,
  },
};
