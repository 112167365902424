import React, { useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { BannerContainer, BannerText, CloseButton, CloseIcon } from './styles';
import Icon from 'components/icons';

const NotificationBanner = ({
  impersonated,
  onlyMobile,
  noCloseButton,
  infoIcon,
  text,
  styles,
  onCloseNotification,
}: {
  impersonated: boolean;
  onlyMobile?: boolean;
  noCloseButton?: boolean;
  infoIcon?: boolean;
  text?: string;
  styles?: React.CSSProperties;
  onCloseNotification?: () => void;
}): JSX.Element => {
  const [showBanner, setShowBanner] = useState(true);

  const handleBannerClose = () => {
    setShowBanner(false);
    onCloseNotification();
  };

  const bannerAvailable = useMemo(() => {
    if (!onlyMobile) return !isMobile && showBanner;
    return showBanner;
  }, [showBanner]);

  const bannerText =
    text ||
    `Analytics has been enhanced to help you find insights more easily and improve your overall
          experience`;

  return (
    bannerAvailable && (
      <BannerContainer
        data-testid="banner-container"
        impersonated={impersonated}
        onlyMobile={onlyMobile}
        infoIcon={infoIcon}
        style={styles}
      >
        {infoIcon && (
          <Icon
            data-testid="info-inactive-icon"
            icon="Info"
            fill={'var(--white)'}
            size="smaller"
            style={{ width: '20px', height: '20px', flex: '0 0 20px' }}
          />
        )}
        <BannerText data-testid="banner-text" dangerouslySetInnerHTML={{ __html: bannerText }} />
        {!noCloseButton && (
          <CloseButton data-testid="close-button" onClick={handleBannerClose}>
            <CloseIcon data-testid="close-button-icon" icon="Cancel2" size="medium" />
          </CloseButton>
        )}
      </BannerContainer>
    )
  );
};

export default NotificationBanner;
