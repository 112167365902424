import UsersPage from 'pages/Users/Users';
import TeamsPage from 'pages/Teams/Teams';
import AccountsPage from 'pages/Accounts/Accounts';
import UsersProfilePage from 'pages/Users/UserProfile';
import TeamsProfilePage from 'pages/Teams/TeamsProfile';
import PlaylistsPage from 'pages/Playlists/Playlists';
import PlaylistItem from 'pages/Playlists/PlaylistDetail/PlaylistDetail';
import AccountProfilePage from 'pages/Accounts/AccountProfile';
import ReportingPage from 'pages/Reporting/Reporting';
import DashboardPage from 'pages/Dashboard/Dashboard';
import AnalyticsPage from 'pages/Analytics/Analytics';
import AccountUsersPage from 'pages/Accounts/components/AccountUsersPage';
import RaxPage from 'pages/RaxPage/Rax';
import AIPage from 'pages/GenIA/GenIA';
import CertificationsPage from 'pages/Certifications/Certifications';

export const routes = [
  {
    title: 'Users',
    to: '/users',
    exact: true,
    Page: UsersPage,
  },
  {
    title: 'Teams',
    to: '/teams',
    exact: true,
    Page: TeamsPage,
  },
  {
    title: 'Reporting',
    to: '/reporting',
    exact: true,
    Page: ReportingPage,
  },
  {
    title: 'Accounts',
    to: '/accounts',
    exact: true,
    Page: AccountsPage,
  },
  {
    title: 'Account Settings',
    to: '/account/:id',
    exact: true,
    Page: AccountProfilePage,
  },
  {
    title: 'Account Users',
    to: '/account/:id/users',
    exact: true,
    Page: AccountUsersPage,
  },
  {
    title: 'Team Profile',
    to: '/team/:id',
    exact: true,
    Page: TeamsProfilePage,
  },
  {
    title: 'User Profile',
    to: '/user/:id',
    exact: true,
    Page: UsersProfilePage,
  },
  {
    title: 'Dashboard',
    to: '/',
    Page: DashboardPage,
  },
  {
    title: 'Playlists',
    to: '/playlists',
    exact: true,
    Page: PlaylistsPage,
  },
  {
    title: 'Edit Playlists',
    to: '/playlist/:id',
    exact: true,
    Page: PlaylistItem,
  },
  {
    title: 'Analytics',
    to: '/analytics',
    exact: true,
    Page: AnalyticsPage,
  },
  {
    title: 'RAX',
    to: '/rax',
    exact: true,
    Page: RaxPage,
  },
  {
    title: 'AI',
    to: '/genAI',
    exact: true,
    Page: AIPage,
  },
  {
    title: 'Certifications',
    to: '/certifications',
    exact: true,
    Page: CertificationsPage,
  },
];
