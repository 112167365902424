import { useContext } from 'react';
import { CertificationContext } from 'contexts/certifications';
import { DaysToExpire, ExpirationContainer, VoucherTable } from './styles';
import { Table, User } from 'assets/styles/styles';
import { capitalize, formatDate, formatScore } from 'utils/helpers';
import displayTeams from 'components/displayTeams/DisplayTeams';
import { Spinner } from 'components/loadings';
import Button from 'components/button';
import ImportantSection from 'components/importantSection/ImportantSection';
interface CertificationsVouchersProps {
  noMobile?: boolean;
}
const CertificationsVouchers = ({ noMobile = false }: CertificationsVouchersProps): JSX.Element => {
  const { fetching, voucherExpirations } = useContext(CertificationContext);
  const mobileTableColumns = 'minmax(200px, 1.3fr) minmax(110px, 0.8fr) 50px 60px 195px 35px 177px';
  const tableColumns = 'minmax(264px, 1.3fr) minmax(190px, 0.8fr) 142px 104px 227px 87px 177px';
  const expiringLabel = (days: number) => {
    const label = days === 0 ? 'today' : `in ${days} day${days > 1 ? 's' : ''}`;
    return `Expiring ${label}`;
  };
  return voucherExpirations.length ? (
    <ImportantSection
      style={{ height: '100%', maxHeight: '351px', minHeight: '146px' }}
      title="SOME VOUCHERS ARE EXPIRING SOON"
      icon="Important"
      noMobile={noMobile}
    >
      <VoucherTable>
        <Table.Container style={{ width: '100%', overflowY: 'auto' }}>
          <Table.Container>
            <Table.Thead height="35px">
              <Table.Tr
                borderBottom={true}
                columns={tableColumns}
                mobileColumns={mobileTableColumns}
              >
                <Table.Th>User</Table.Th>
                <Table.Th>Team(s)</Table.Th>
                <Table.Th>Certification</Table.Th>
                <Table.Th> Status</Table.Th>
                <Table.Th>Expiration date</Table.Th>
                <Table.Th>Result</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody style={{ height: 'auto' }} height="54px">
              {fetching && (
                <Table.Loading>
                  <Spinner active={fetching} size="25" />
                </Table.Loading>
              )}

              {!fetching &&
                voucherExpirations?.length > 0 &&
                voucherExpirations?.map((report) => {
                  const {
                    voucher_id,
                    user_name,
                    user_email,
                    certification_short_name,
                    days_to_expire,
                    teams,
                    status,
                    expiration_date,
                    score,
                  } = report;
                  return (
                    <Table.Tr
                      borderBottom={true}
                      key={voucher_id}
                      columns={tableColumns}
                      mobileColumns={mobileTableColumns}
                    >
                      <Table.Td>
                        <User.Container>
                          <User.Name>{user_name}</User.Name>
                          <User.Email>{user_email}</User.Email>
                        </User.Container>
                      </Table.Td>
                      <Table.Td>{displayTeams({ teams, direction: 'row' })}</Table.Td>
                      <Table.Td>{certification_short_name}</Table.Td>
                      <Table.Td>{capitalize(status)}</Table.Td>
                      <Table.Td>
                        <ExpirationContainer>
                          <span>{formatDate(expiration_date)}</span>
                          <DaysToExpire today={days_to_expire === 0}>
                            {expiringLabel(days_to_expire)}
                          </DaysToExpire>
                        </ExpirationContainer>
                      </Table.Td>
                      <Table.Td>{formatScore(score)}</Table.Td>
                      <Table.Td>
                        <a href={`mailto:sales@ine.com`}>
                          <Button $secondary size="small">
                            Contact INE to extend
                          </Button>
                        </a>
                      </Table.Td>
                    </Table.Tr>
                  );
                })}
            </Table.Tbody>
          </Table.Container>
        </Table.Container>
      </VoucherTable>
    </ImportantSection>
  ) : null;
};

export default CertificationsVouchers;
