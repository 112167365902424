import { screenLg, screenMd, screenSm, screenXs } from 'constants/styles/breakpoints';
import styled from 'styled-components';

export const ClearInput = styled.div`
  position: absolute;
  div {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    margin: 10px 0px 0 190px;
    border-radius: 50%;
    transition: background-color 0.3s;
    ${screenXs} {
      margin: 10px 0px 0 210px;
    }
    ${screenSm} {
      margin: 10px 0px 0 57vw;
    }
    ${screenMd} {
      margin: 10px 0px 0 190px;
    }
    &:hover {
      background-color: #fff;
    }
  }
`;

export const FiltersContainer = styled.div`
  ${screenLg} {
    min-width: 215px;
    max-width: 215px;
  } ;
`;

export const FiltersWrapper = styled.div`
  width: 100%;
  position: sticky;
  top: 80px;

  display: flex;
  align-items: center;
  gap: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  ${screenSm} {
    flex-wrap: nowrap;
  }
  ${screenLg} {
    min-width: 215px;
    max-width: 215px;
    flex-direction: column;
    align-items: flex-start;
  } ;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FilterTitle = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: var(--light-gray-darker);
  margin-bottom: 5px;
`;

export const SpinnerContainer = styled.div`
  position: relative;
  opacity: 50%;
`;

export const SelectContainer = styled.div<{ showCustom?: boolean; isTablet?: boolean }>`
  display: grid;
  grid-template-columns: ${({ showCustom }) => (showCustom ? '85% 15%' : '100%')};
  .ant-picker-suffix svg {
    width: 18px;
    height: 18px;
  }
  button {
    height: 43px !important;
  }
  div:first-of-type {
    ${({ showCustom }) => showCustom && 'margin-right: 5px;'};
  }
  ${screenMd} {
    width: 100%;
    grid-template-columns: ${({ showCustom }) => (showCustom ? '172px 43px' : '100%')};
    ${({ isTablet }) =>
      isTablet &&
      `
    max-width: unset;
    grid-template-columns: 100%;
  `};
  }
`;
